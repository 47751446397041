import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import KeyResources from "../../../components/common/KeyResources";
import TopFormWithCenter from "../../../components/common/knowledge/Header";
import Cards from "../../../components/common/knowledge/Cards";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import {GoToPopup} from "../../homepage"
import {CookiesPoup} from "../../homepage"

import { OnScrollPopup } from "../hr-chatbot";

export const blogImg = require("../../../assets/img/key-resources/bc_1_4.png");
export const whitepaperImg = require("../../../assets/img/key-resources/wp_1_4.png");
export const videoImg = require("../../../assets/img/key-resources/vc_1_4.png");

export const tick_icon = require("../../../assets/img/tick-icon.png");

const resoucesData = [
  {
    image: blogImg,
    header: "Reinventing the role of HR during the COVID-19 Pandemic ",
    url:
      "https://blog.workativ.com/reinventing-the-role-of-hr-during-the-covid-19-pandemic-business-continuity-planning-chatbots-and-whatnot/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header:
      "An improvised helpdesk provides numerous cost benefits and savings.",
    url: "/conversational-ai-platform/cost-reduction",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header: "Automate HR & IT requests using an AI-powered chatbot",
    url: "https://youtu.be/Gbx-MXB73uU",
    link: "Watch Now",
    alt: "videoImg"
  }
];
export const cardData = [
  {
    href: "#section-1",
    header: "What does automation mean overall for the organization?",
    content:
      "The need for automation is common across organizations due to processes that kill productivity.",
    backgroundClass: "card_one"
  },
  {
    href: "#section-2",
    header: "Limitations of Automations provided by ITSM tools",
    content:
      "Going beyond the ordinary, organizations need an innovative solution that helps cut costs.",
    backgroundClass: "card_two"
  }
];

export default function KnowledgePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState();
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title=" Process Automation for your IT & HR Helpdesk with AI Chatbot | Workativ Assistant"
        description="Automate the IT & HR support process for reducing operational costs & MTTR, enhancing work continuity, increasing efficiency and staff productivity, visibility and control of workflows."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle=" Process Automation for your IT & HR Helpdesk with AI Chatbot | Workativ Assistant"
        ogDescription="Automate the IT & HR support process for reducing operational costs & MTTR, enhancing work continuity, increasing efficiency and staff productivity, visibility and control of workflows."
      />
      <Container>
      <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT">
          <>
            <div className="gen_vs_true">
              <section
                className="wv-banner wv-landing-page-1 --green-bg wv-banner-h320"
                id="scrollToHome"
              >
                <TopFormWithCenter>
                  <TopFormWithCenter.Content>
                    General automation vs true automation
                  </TopFormWithCenter.Content>
                  <TopFormWithCenter.Header>
                    Better user experience, reduced costs and increased
                    productivity with true automation from Workativ Assistant
                  </TopFormWithCenter.Header>
                 
                  <RequestForm isFooterForm={false} />
                </TopFormWithCenter>
              </section>
              <section className="cards_center">
                <Cards cards={cardData} />
              </section>

              <section className="wv-main-content wv-section-mv-p0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 --sh-sm mb-4">
                      <div className="dropdown wv-dropdown">
                        <button
                          className={`link ${isOpen ? "active" : ""}`}
                          onClick={() => setOpen(!isOpen)}
                        >
                          Explore this articles
                        </button>
                        <div
                          id="myDropdown"
                          className={`accordion-item submenu dropdown-content ${
                            !isOpen ? "collapsed" : ""
                          }`}
                        >
                          <li>
                            <AnchorLink
                              offset={300}
                              className="url_manipulation"
                              href="#section-1"
                            >
                              What does automation mean?
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={200}
                              className="url_manipulation"
                              href="#section-2"
                            >
                              General automation vs. True automation
                            </AnchorLink>
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 --hd-sm">
                      <aside className="wv-sidenav max-width-255" id="myHeader">
                        <p className="wv-sm-heading">Explore this article</p>
                        <ul className="mb-5" style={{ width: "100%", float: "left" }}>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-1"
                              className={
                                isActive === "What does automation mean?"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive("What does automation mean?");
                              }}
                            >
                              {" "}
                              What does automation mean?
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-2"
                              className={
                                isActive ===
                                "General automation vs. True automation"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  "General automation vs. True automation"
                                );
                              }}
                            >
                              {" "}
                              General automation vs. True automation
                            </AnchorLink>
                          </li>
                        </ul>
                        <AnchorLink offset={200} href="#scrollToHome">
                          {" "}
                          Back to top ↑
                        </AnchorLink>
                      </aside>
                    </div>

                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="wv-content-area">
                        <section className="wv-content-section">
                          <section
                            className="benefits_knowledge"
                            id="section-1"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="">
                                  <div className="">
                                    <section className="cards_without_bg">
                                      <h4 className="wv-heading-h4 mb-2">
                                        In the ITSM space, what does automation
                                        mean overall for the organization?
                                      </h4>

                                      <div className="full_width_li">
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Reducing operational costs
                                          </h4>
                                        </div>
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Improving service delivery and
                                            reduced service resolution time
                                            (MTTR)
                                          </h4>
                                        </div>
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Enhancing work continuity
                                          </h4>
                                        </div>
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Increasing efficiency and staff
                                            productivity
                                          </h4>
                                        </div>
                                        <div className="col-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Visibility and control of workflows
                                          </h4>
                                        </div>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary mb-3 ">
                                          Reducing operational costs and MTTR
                                          are the areas that are paramount in IT
                                          support. The need for automation is
                                          common across organizations due to
                                          processes that kill productivity. Most
                                          often, service desk support agents
                                          consume time on low-value tasks and
                                          routine work. Instead, time spent on
                                          delivering exceptional customer
                                          support and delivering high-value work
                                          should be the norm.
                                        </p>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </section>
                        <section className="bg_section_knowledge">
                          <div className="container">
                            <div className="row">
                              <div className="col pr-0 pl-0  -call-reflection">
                                <div className="container">
                                  <div className="main-slider-left">
                                    <h1>
                                      Why organizations choose to automate the
                                      IT support process
                                    </h1>
                                    <ul style={{ width: "100%", float: "left" }}>
                                      <li>Cut manual effort</li>
                                      <li>Level-up IT productivity</li>

                                      <li>
                                        End-to-end automation of service
                                        delivery and service support processes
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="wv-section-mv-p content_para content_ul">
                          <div className="container">
                            <div className="row">
                              <div className="col">
                                <h2 className="wv-heading-h4 mb-3">
                                  Limitations of Automations provided by ITSM
                                  tools
                                </h2>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  While ITSM tools can automate a lot of
                                  everyday tasks, it is not enough as they would
                                  still require the involvement of an IT team to
                                  handle issues.
                                </p>
                                <ul className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content" style={{ width: "100%", float: "left" }}>
                                  <li>
                                    ITSM tools focus on task automations. i.e.,
                                    ticket routing, ticket classification.
                                  </li>
                                  <li>
                                    ITSM tools lack empirical data to trigger
                                    automation; they are just not intelligent!
                                  </li>
                                  <li>
                                    ITSM tools do not orchestrate with 3rd party
                                    applications. The ones that do focus are on
                                    Enterprise space and are very expensive.
                                  </li>
                                  <li>
                                    The self-service portal approach is static
                                    and still requires human intervention.
                                  </li>
                                </ul>
                                <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mt-4 mb-4">
                                  Automation should be beneficial and add real
                                  value to an organization, and in that it
                                  should complete the job without human
                                  involvement. We term this as true automation.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                  Self-service in workplace support is useful
                                  only when an employee of an organization can
                                  genuinely self-resolve issues or requests with
                                  ease and reap its merits to the fullest. While
                                  it is true that self-service portals are
                                  employed by many organizations today, it still
                                  nudges employees to reach out for further help
                                  from a service desk agent. Furthermore, it
                                  relies heavily on the knowledge base and is
                                  neither innovative nor productive for
                                  employees.
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="wv-section-mv-p content_para">
                          <div id="section-2">
                            <div className="container">
                              <div className="row">
                                <div className="col content_full">
                                  <h4 className="wv-heading-h4">
                                    How does Workativ Assistant deliver true
                                    automation?
                                  </h4>
                                  <h4 className="wv-heading-h4-h4">
                                    Automated resolution
                                  </h4>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    With a robust AI and automation engine,
                                    Workativ Assistant enables automated
                                    resolution. Therefore, the mundane and
                                    repetitive issues are kept away from the
                                    service desk or IT support team, reducing
                                    the team’s involvement in low-value tasks.
                                  </p>
                                  <h4 className="wv-heading-h4-h4">
                                    Self-resolution
                                  </h4>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    Workativ Assistant is a truly modern AI-led
                                    self-service tool that surpasses other
                                    ordinary chatbots with the intelligent
                                    automation capability. The virtual assistant
                                    enables employees to resolve requests on
                                    their own at any time without having to wait
                                    in the queue to have their IT issues
                                    resolved.
                                  </p>
                                  <h4 className="wv-heading-h4-h4">
                                    End-to-end automation through tight
                                    integration
                                  </h4>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    Workativ Assistant integrates with several
                                    conventional IT and business applications
                                    such as Microsoft, Workday, and Active
                                    Directory to handle a variety of use cases.
                                    Also, various other ITSM helpdesk solutions
                                    such as Freshdesk, Zoho, Zendesk,
                                    ServiceNow, Jira, and IBM Control Desk are
                                    integrated, to achieve end-to-end
                                    automation.
                                  </p>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    Workativ Assistant offers a complete AI and
                                    automation solution for operational tasks
                                    and enhances IT support with automated
                                    resolution. Automating everyday tasks and
                                    allowing resources to focus on other, more
                                    complex issues helps increase an employee’s
                                    productivity and free up IT resources.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
          <KeyResources cards={resoucesData} />
          <RequestForm isFooterForm={true} />
          {/* <GoToPopup />
          <CookiesPoup /> */}
        </Layout>
        {/* {isSmall ? null : <OnScrollPopup />} */}

      </Container>
    </>
  );
}
